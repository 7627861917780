/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  IPageBlog,
  IPost,
  IPagination,
  ILocation,
  ICategory
} from '@standardTypes';
import { usePostsWithCategory } from '@utils';

import React from 'react';

import { Layout } from '@components/layout';
import {
  BlogHero,
  CaseStudyPosts,
  NewestPosts,
  SectionsWithBackround,
  NewsletterSection,
  TestersEyePosts,
  VideoAndPodcastPosts
} from '@components/organisms/standard';

interface BlogProps {
  pageContext: {
    page: IPageBlog;
    posts: Array<IPost>;
    pagination: IPagination;
    categories: ICategory[];
    translationUrls: Record<string, string>;
  };
  location: ILocation;
}

const BlogPage: React.FC<BlogProps> = ({ pageContext }): JSX.Element => {
  const { page, posts, translationUrls } = pageContext;

  const newestPosts = posts.slice(0, 4);
  const caseStudyPosts = usePostsWithCategory(posts, 'case-study').slice(0, 4);
  const testersEyePosts = usePostsWithCategory(posts, 'testers-eye').slice(
    0,
    4
  );
  const videoAndPodcastPosts = usePostsWithCategory(
    posts,
    'video-and-podcast'
  ).slice(0, 4);

  const { ACF_Blog_Newsletter, ACF_Blog_Subtitle } = page;
  const { newsletterCheckbox, newsletterSubtitle, newsletterTitle } =
    ACF_Blog_Newsletter;
  const { blogSubtitle } = ACF_Blog_Subtitle;
  return (
    <Layout {...page} translationUrls={translationUrls}>
      <BlogHero subTitle={blogSubtitle} />
      <SectionsWithBackround numberOfBackgrounds={7}>
        <NewestPosts posts={newestPosts} />
        <CaseStudyPosts posts={caseStudyPosts} />
        <TestersEyePosts posts={testersEyePosts} />
        <VideoAndPodcastPosts posts={videoAndPodcastPosts} />
        <NewsletterSection
          title={newsletterTitle}
          subtitle={newsletterSubtitle}
          checkbox={newsletterCheckbox}
        />
      </SectionsWithBackround>
    </Layout>
  );
};
export default BlogPage;
